.foundation-introduce {
  .foundation-introduce-container {
    background-size: 100% !important;

    box-shadow: 0px 5px 24px 0px rgba(91, 91, 91, 0.23);

    margin-bottom: 40px;
    overflow: hidden;
    h4 {
      font-size: 24px;
      font-weight: 700;
      color: #333333;
      text-align: center;
      margin-top: 80px;
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 95px;
        height: 1px;
        background: #af141c;
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .introduce-container-content {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 48px 164px;
  }
  .img-content {
    width: 429px;
    height: 505px;
    position: relative;
    margin-right: 36px;
    img {
      background-color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      width: 388px;
      height: 466px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 388px;
      height: 466px;
      background-color: #af141c;
      left: 0;
      bottom: 0;
    }
  }
  .text-content {
    flex: 1;
    font-size: 20px;
    color: #333333;
    line-height: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .show-imgs{
    margin-top: 30px;
    a{
      display: inline-block;
      width: 200px;
      height: 120px;
      margin: 5px;
      cursor:pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.two-tab{
  letter-spacing: 5px;
  font-size: 21px;
  padding: 40px;
  min-height: 660px;
  .two-tab-span{
    position: relative;
    padding-left: 30px;
    &::before{
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      /*背景色是渐变色*/
      background: linear-gradient(180deg, #fd8f95, #af141c);
      border-radius: 10px;
      top: 3px;
      left: 0px;
    }
  }
}
.three-tab{
  letter-spacing: 5px;
  font-size: 18px;
  padding: 40px;
  min-height: 660px;
  .three-title{
    font-weight: bold;
    position: relative;
    padding-left: 30px;
    &::before{
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      /*背景色是渐变色*/
      background: linear-gradient(180deg, #fd8f95, #af141c);
      border-radius: 10px;
      top: 5px;
      left: 0px;
    }
  }
}


