.crumbs {
  height: 78px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.core {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  .core-crumbs-icon {
    width: 78px;
    height: 78px;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  .core-crumbs {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.w1500{
  width: 1500px;
}
.right {
  background-color: #af141c;
  flex: 1;
}
.left {
  flex: 1;
}
.core-nav {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;

  li {
    height: 78px;
    background-color: #af141c;
    list-style: none;
    color: #fff;
    line-height: 78px;
    cursor: pointer;
    font-size: 20px;

    a {
      color: #fff;
      display: block;
      padding: 0 48px;
    }
  }
  .core-nav-active {
    background-color: #e8b773;
    transition: all 0.5s;
  }
}

