.banner {
  height: 500px;
  width: 100%;
  position: relative;
  .left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 50px;
    color: #fff;
    background: rgba(0, 0, 0, .3);
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }
  .right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 50px;
    color: #fff;
    background: rgba(0, 0, 0, .3);
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }
}
.banner-item {
  height: 500px;
  display: flex;
  // background-color: #333;
  img {
    width: 100%;
    height: 100%;
  }
  div{
    width: 100%;
    height: 500px;
  }
}

