.LiItem {
  list-style: none;
  height: 1px;
  border-bottom: 1px solid #d2d2d2;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  width: 100%;
  max-width: 639px;
  a {
    display: block;
    width: 100%;
    color: #333;
    white-space: nowrap; /*超出的空白区域不换行*/
    overflow: hidden; /*超出隐藏*/
    text-overflow: ellipsis; /*文本超出显示省略号*/
  }
  cursor: pointer;
  img {
    margin: 0 15px;
  }
  &:hover {
    a {
      color: #af141c;
    }
  }
}

