.navigationBar {
  width: 100%;
  box-sizing: border-box;
  height: 78px;
  background: #af141c;
  .navigationBar-container {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  img {
    height: 50px;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    .navigationBar-item {
      list-style: none;
      height: 78px;
      box-sizing: border-box;
      line-height: 78px;
      a {
        color: #fff;
        text-decoration: none;
        padding: 0 40px;
        font-size: 16px;
        display: block;
        position: relative;
        display: block;
        span{
          display: none;
        }
      }
      .activeNavLink {
        span {
          width: 40px;
          height: 6px;
          border-radius: 4px;
          background-color: #e8b773;
          position: absolute;
          display: block;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &:last-child {
      a {
        border-right: none;
        // padding-right: 0;
      }
    }
  }
}


