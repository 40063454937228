ul,li{
  list-style: none;
}
.share-center {
  .banner{
    height: 500px;
    width: 100%;
    position: relative;
    .banner-item {
      height: 500px;
      display: flex;
      // background-color: #333;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .big-title{
      position: absolute;
      top: 32%;
      left: 16%;
      font-size: 108px;
      font-weight: 600;
      color: transparent;
      background: linear-gradient(180deg, #fad7b3 0%, #efbd87 100%);
      -webkit-background-clip: text;
    }
  }
  .share-container{
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 10px auto;
    .top-introduce{
      margin: 20px 0;
      background-color: #fff;
      box-shadow: 0px 5px 24px 0px rgba(91, 91, 91, 0.23);
      display: flex;
      flex-direction: column;
      border-radius: 0px 4px 4px 0px;
      .introduce-title{
        position: relative;
        .share-title{
          position: absolute;
          top:49%;
          left: 39%;
          font-size: 24px;
          font-weight: 600;
          color: #AF141C;
        }
      }
      .introdece-p{
        padding: 10px 60px;
        font-size: 18px;
        line-height: 36px;
      }
    }
  }
  .list{
    width: 1200px;
    margin: 20px auto;
    background: #fff;
    border-radius: 0 4px 4px 0;
    .list-container{
      padding: 40px 20px 50px;
      display: flex;
      justify-content: space-between;
      .list-imgs{
        width: 50%;
        img{
          border-radius: 4px;
        }
      }
      .list-texts{
        width: 45%;
        margin: 20px 0;
        .texts-top{
          position: relative;
          img{
            width: 100%;
            height: 100%;
          }
          h2{
            position: absolute;
            top: 8px;
            left: 15px;
            color: #ffffff;
            font-size: 22px;
          }
        }
        .texts-mid{
          margin-top:10px;
          p{
            margin: 0;
            font-weight: 600;
            font-size: 17px;
          }
          .downloads{
            border-left: 1px solid #EAEAEA;
            padding-left: 10px;
            margin-top: 5px;
            .download-item{
              height: 50px;
              font-size: 17px;
              display: flex;
              align-items: center;
              i{
                font-size: 10px;
                color:#E7E7E7 ;
              }
              a{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 5px;
              }
              .ant-tooltip-inner{
                width: 200px !important;
              }
            }
          }
        }
        .texts-bottom{
          a{
            display: inline-block;
            width: 161px;
            height: 50px;
            background: #AF141C;
            text-align: center;
            padding: 10px 20px;
            border: 1px solid #AF141C;
            border-radius: 26px;
            font-size:18px;
            color: #fff;
          }
        }
      }
    }
  }
}

