.home-container {
  width: 100%;
  overflow-x: hidden;
  .home-about {
    height: 364px;
    background: #ffffff;
    box-shadow: 0px 0px 27px 0px rgba(144, 144, 144, 0.39);
    border-radius: 6px;
    position: relative;
    margin-top: -84px;
    margin-bottom: 40px;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    position: relative;
    .float-button {
      // transform: scale(0.9);
      width: 150px;
      overflow: hidden;
      position: absolute;
      right: -165px;
      top: 100px;
      display: flex;
      flex-direction: column;
      a {
        flex: 1;
        width: 150px;
        img {
          width: 150px;
          height: auto;
        }
      }
    }
    /*春节*/
    /*.float-button {
      // transform: scale(0.9);
      width: 198px;
      height: 326px;
      overflow: hidden;
      position: absolute;
      right: -200px;
      top: 100px;
      .float-button-container{
        position: absolute;
        left: 35px;
        bottom: 10px;
        width: 135px;
        height: 140px;
        display: flex;
        flex-direction: column;
        a {
          width: 135px;
          text-align: center;
          margin: 10px  0;
          height: 45px;
          font-size: 12px;
          color: #ffffff;
          font-weight: 600;
          &:last-child{
            height: 36px;
          }
        }
      }
    }*/
    /*春节*/
    /*.float-num {

      width: 232px;
      height: 239px;
      padding: 0px 10px;
      background: rgba(170, 23, 39, 0.95);
      position: absolute;
      top: 0;
      left: -200px;
      display: flex;
      flex-direction: column;
      .time {
        position: absolute;
        right: 10px;
        bottom: 8px;
        color: #fff;
      }
      .float-num-item {
        width: 110px;
        height: 65px;
        margin-left: 90px;
        !*flex: 1;*!
        display: flex;
        flex-direction: column;
        &:nth-of-type(1) {
          border-bottom: 1px dashed #fab997;
        }
        &:first-child{
          margin-top: 50px;
        }

        .number-container {
          height: 30px;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          ul {
            flex: 1;
            height: 30px;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            li {
              width: 18px;
              list-style: none;
              height: 30px;
              flex: 1;
              margin-right: 3px;
              border-radius: 2px;
              overflow: hidden;

              div {
                width: 100%;
                height: 100%;
                animation: identifier 0.7s ease-out;
                span {
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                  float: left;
                  border-radius: 2px;
                  margin-bottom: 35px;
                  margin-left: 0;
                  color: rgba(170, 23, 39, 0.95);
                  font-size: 25px;
                  font-weight: 700;
                  line-height: 30px;
                  text-align: center;
                }
              }
            }
          }
          span {
            width: 36px;
            font-size: 12px;
            color: #feeed7;
          }
        }
      }

      h4 {
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
      }
    }*/
    .float-num {

      width: 130px;
      height: 211px;
      padding: 0px 10px;
      background: rgba(170, 23, 39, 0.95);
      position: absolute;
      top: 0;
      left: -150px;
      display: flex;
      flex-direction: column;
      box-shadow: 3px 5px 10px 0px rgba(129, 128, 128, 0.59);
      .time {
        position: absolute;
        right: 10px;
        bottom: 8px;
        color: #fff;
      }
      .float-num-item {
        flex: 1;
        display: flex;
        flex-direction: column;

        &:nth-of-type(1) {
          border-bottom: 1px dashed #fab997;
        }

        .number-container {
          height: 30px;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          ul {
            flex: 1;
            height: 30px;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            li {
              width: 18px;
              list-style: none;
              height: 30px;
              flex: 1;
              margin-right: 3px;
              border-radius: 2px;
              overflow: hidden;

              div {
                width: 100%;
                height: 100%;
                animation: identifier 0.7s ease-out;
                span {
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                  float: left;
                  border-radius: 2px;
                  margin-bottom: 35px;
                  margin-left: 0;
                  color: rgba(170, 23, 39, 0.95);
                  font-size: 25px;
                  font-weight: 700;
                  line-height: 30px;
                  text-align: center;
                }
              }
            }
          }
          span {
            width: 36px;
            font-size: 12px;
            color: #feeed7;
          }
        }
      }

      h4 {
        margin: 15px 0;
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    &::after {
      content: "";
      width: 48px;
      height: 48px;
      background: #af141c;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    h4 {
      font-size: 32px;
      font-weight: 700;
      color: #af141c;
      position: relative;
      // &::after {
      //   content: "";
      //   width: 92px;
      //   height: 4px;
      //   background: #af141c;
      //   position: absolute;
      //   bottom: -10px;
      //   left: 0;
      // }
    }
    // 首页关于我们 轮播图
    .carousel-content{
      flex: 1;
      position: relative;
      width: 543px;
      height:304px;
      margin-right: 40px;
      img{
        width: 100%;
        height: 100%;
        margin-left: 0;
      }
    }
/*    img {
      width: 543px;
      height: 304px;
      margin-right: 40px;
    }*/
    p {
      font-size: 21px;
      margin: 0;
      text-indent: 2em;
      line-height: 36px;
      &:last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
  .home-position-box {
    margin-bottom: 40px;
  }
  .home-position {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .home-position-item {
      /*width: 294px;
      height: 92px;*/
      width: 232px;
      height: 74px;
      background: #eee;
      margin-bottom: 6px;
      transition: all 0.5s;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 4px 7px 0px rgba(91, 91, 91, 0.32);
      }
    }
  }
  .home-position-content {
    height: 450px;
    background: #ffffff;
    box-shadow: 0px 0px 27px 0px rgba(144, 144, 144, 0.39);
    border-radius: 6px;
    padding: 30px;
    margin-bottom: 40px;
    h4 {
      font-size: 32px;
      font-weight: 700;
      color: #af141c;
    }
    .content {
      display: flex;
      img {
        width: 556px;
        height: 313px;
        margin-left: 40px;
      }
      p {
        color: #333;
        padding: 0;
        margin: 0;
        font-size: 15px;
        line-height: 32px;
        text-indent: 2em;
        width: 556px;
        height: 313px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 4px;
          background-color: #eeeeee;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #af141c;
          border-radius: 4px;
        }
      }
      .carousel-content{
        position: relative;
        width: 556px;
        height:313px;
        margin-left: 20px;
        img{
          width: 100%;
          height: 100%;
          margin-left: 0;
        }
      }
    }
  }
  .home-video-swiper {
    height: 340px;
    display: flex;
    margin-bottom: 40px;
    .swiper-item {
      width: 516px;
      height: 340px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      video {
        width: 100%;
        height: 290px;
        background-color: #999;
      }
      h4 {
        flex: 1;
        background-color: #fff;
        text-align: center;
        line-height: 43px;
      }
    }
    .swiper-container {
      width: 1058px;
    }
    .arrow-left {
      width: 44px;
      height: 50px;
      background-color: #af141c;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 16px;
        height: 22px;
      }
    }
    .arrow-right {
      width: 44px;
      height: 50px;
      background-color: #af141c;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .arrow {
      width: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 44px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #af141c;
      img {
        width: 16px;
        height: 22px;
      }
    }
    .swiper-button-next img {
      transform: rotate(180deg);
    }
    .disable {
      background-color: #dbdbdb;
    }
  }
}
@keyframes identifier {
  from {
    transform: translateY(0);
  }
}

