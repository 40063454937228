body {
  margin: 0;
  background: #f7f7f7;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.w1400 {
  width: 1400px;
  margin: 0 auto;
}
.ant-btn{
  border: none;
  font-size: 20px;
}
