.text-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  height: 186px;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 10px;
  
  &:hover {
    color: #af141c;
    .text-img-box .isVideo {
      display: block;
      justify-content: center;
      align-items: center;
    }
    .text-img-box .isImg {
      display: block;
      justify-content: center;
      align-items: center;
    }
    .text-img-item {
      transform: scale(1.1);
      transition: transform 0.5s;
    }
  }
  .text-img-box {
    height: 165px;
    width: 220px;
    overflow: hidden;
    position: relative;
    margin-left: 10px;
    .isVideo {
      background: rgba(0, 0, 0, 0.2);
      width: 220px;
      height: 165px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      display: none;
      img {
        width: 37px;
        position: absolute;
        height: 37px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .isImg {
      background: rgba(0, 0, 0, 0.2);
      width: 220px;
      height: 165px;
      position: absolute;
      left: 0;
      top: 0;
      display: none;
      z-index: 10;
      img {
        width: 37px;
        position: absolute;
        height: 37px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  img {
    width: 220px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .text-img-title {
    font-weight: 700;
    flex: 1;
    height: 100%;
    line-height: 186px;
    margin-left: 30px;
    overflow: hidden; /*超出部分隐藏*/
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    white-space: nowrap; /*规定段落中的文本不进行换行 */
    margin-right: 30px;
    cursor: pointer;
    font-size: 18px;
    color: #333;
    &:hover {
      color: #af141c;
    }
  }
  .text-img-time {
    width: 175px;
    height: 114px;
    border-left: 1px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .day {
      font-size: 32px;
      text-align: center;
      color: #af141c;
    }
    text-align: center;
    .line {
      width: 18px;
      height: 1px;
      background: #e8b773;
    }
    .year {
      font-size: 16px;
      text-align: center;
      color: #af141c;
    }
  }
}

