.titler-title {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: -4px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-bottom: 4px solid #af141c;
  margin-bottom: 20px;
  span {
    display: block;
    width: 210px;
    height: 48px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 48px;
  }
}

