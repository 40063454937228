.information-container {
  height: auto;
  .ant-pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    span {
      color: #333333;
    }
    span[disabled] {
      color: #999;
    }
  }
  .ant-pagination-item-active {
    border-color: #af141c;
    background-color: #af141c;
    a {
      color: #fff;
    }
  }
}

