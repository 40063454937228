.patybuilding {
  margin-bottom: 30px;
  .propaganda {
    width: 1200px;
    height: 418px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    .left-img {
      width: 480px;
      height: 360px;
      border-radius: 8px;
      background-color: #eee;
    }
    .right-content {
      height: 360px;
      max-width: 699px;
      display: flex;
      flex-direction: column;
      .right-content-list {
        margin: 0;
        padding: 0;
        flex: 1;
        width: 100%;
      }
      .ant-pagination {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        justify-self: flex-end;
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        span {
          color: #333333;
        }
        span[disabled] {
          color: #999;
        }
      }
      .ant-pagination-item-active {
        border-color: #af141c;
        background-color: #af141c;
        a {
          color: #fff;
        }
      }
    }
  }
  .advertisement {
    height: 140px;
    background: #af141c;
    border-radius: 70px;
    overflow: hidden;
    img {
      width: 100%;
      height: 140px;
    }
  }
  .study {
    width: 1200px;
    height: 690px;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .study-img {
      width: 100%;
      height: 280px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      img {
        width: 373px;
        height: 280px;
        // background-color: skyblue;
      }
    }
    .study-list {
      display: flex;
      flex: 1;
      flex-direction: column;
      .study-list-ul {
        margin: 0;
        flex: 1;
        padding: 0;
        .LiItem {
          min-width: 1160px;
          max-width: 1160px;
        }
      }

      .ant-pagination {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        span {
          color: #333333;
        }
        span[disabled] {
          color: #999;
        }
      }
      .ant-pagination-item-active {
        border-color: #af141c;
        background-color: #af141c;
        a {
          color: #fff;
        }
      }
    }
  }
  .activity {
    .ant-pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      span {
        color: #333333;
      }
      span[disabled] {
        color: #999;
      }
    }
    .ant-pagination-item-active {
      border-color: #af141c;
      background-color: #af141c;
      a {
        color: #fff;
      }
    }
  }
}

