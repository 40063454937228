.siteMapFooter {
  height: 280px;
  background-color: #fff;

  .info {
    background-color: #af141c;
    height: 220px;
    div {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      ul {
        display: flex;
        align-items: center;
        padding: 0 50px;
        flex: 1;
        margin: 0;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 133px;
        li {
          width: 50%;
          list-style: none;
          color: #fff;
          display: flex;
          flex-direction: row;
          img{
            margin-right: 10px;
          }
        }
      }
      .codeStyle{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 40px;
        img{
          width: 100px;
          height: 100px;
          margin-top: 3px;
        }
        span{
          color: #ffffff;
          font-size: 14px;
          margin-top: 13px;
        }
      }
    }
  }
  .code {
    background-color: #fff;
    height: 60px;
    div {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #999999;
      font-size: 12px;
      line-height: 24px;
      p {
        margin: 0;
      }
    }
  }
}

