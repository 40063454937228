.titlel-title {
  width: 1200px;
  margin: 40px auto 20px;
  height: 48px;
  display: flex;
  flex-direction: row;
  border-bottom: 4px solid #af141c;
  position: relative;
  span {
    display: block;
    width: 210px;
    height: 48px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 48px;
  }
  img {
    position: absolute;
    left: 210px;
    bottom: 0;
  }
}

