.memorabilia {
  .memorabilia-timeline {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 1200px;
  }
  .memorabilia-timeline-swiper {
    width: 1200px;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .swiper-container {
    width: 1058px;
    border-bottom: 10px solid #af141c;
  }
  .swiper-item {
    width: 150px;
    height: 150px;
    color: #727272;
    font-weight: 700;
    margin-bottom: 10px;
    border-radius: 50%;
    box-sizing: border-box;
    text-align: center;
    .swiper-item-active-nei {
      width: 134px;
      height: 134px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      font-size: 28px;
      justify-content: flex-end;
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 8px;
        bottom: -28px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #af141c;
      }
    }
  }
  .swiper-item-active {
    border: 4px solid #af141c;
    transition: background 0.3s;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiper-item-active-nei {
      width: 134px;
      height: 134px;
      background-color: #af141c;
      border-radius: 50%;
      font-size: 40px;
      line-height: 134px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 8px;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #af141c;
      }
    }
  }
  .swiper-button-prev {
    width: 44px;
    height: 50px;
    background-color: #af141c;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    img {
      width: 16px;
      height: 22px;
    }
  }
  .swiper-button-next {
    width: 44px;
    height: 50px;
    background-color: #af141c;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
    cursor: pointer;
    user-select: none;
    img {
      width: 16px;
      height: 22px;
    }
  }
  .disable{
    background-color: #dbdbdb;
  }
  .memorabilia-timeline-content {
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0px 5px 24px 0px rgba(91, 91, 91, 0.23);
    padding: 70px 40px;
    display: flex;
    flex-direction: column;
  }
  .arrow-up-container {
    height: 80px;
    img {
      margin-left: 68px;
    }
  }
  .memorabilia-list {
    margin: 0;
    padding: 0;
    border-left: 4px solid #af141c;
    margin-left: 108px;
    margin-top: -2px;
    padding-top: 50px;
    position: relative;
    ul {
      margin: 0;
      padding: 0;
    }
    .mounth {
      position: absolute;
      left: -100px;
      top: 73px;
      font-size: 28px;
      color: #828282;
      font-weight: 700;
    }
    li {
      list-style: none;
      height: 190px;
      margin-left: 80px;
      margin-bottom: 80px;
      cursor: pointer;
      display: flex;
      a {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .circle {
          position: absolute;
          left: -104px;
          top: 28px;
        }
        .text-container {
          flex: 1;
          background-color: #ededed;
          padding: 0 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h4 {
            font-size: 24px;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          p {
            font-size: 14px;
            color: #686868;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
        .imginfo {
          width: 252px;
          background-color: pink;
          margin-left: 10px;
        }
        .time-container {
          position: absolute;
          left: -190px;
          top: 26px;
          font-size: 28px;
          color: #828282;
          font-weight: 700;
        }

        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          left: -34px;
          top: 28px;
          border-top: 22px solid transparent;
          border-right: 34px solid #ededed;
          border-bottom: 22px solid transparent;
        }
      }
      &:hover {
        .text-container {
          background-color: #af141c;
          color: #fff;
          h4 {
            color: #fff;
          }
          p {
            color: #fff;
          }
        }
        a {
          &::after {
            border-right: 34px solid #af141c;
          }
        }
      }
    }
  }
}

