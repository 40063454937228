.contact-content{
  width: 1000px;
  margin: 30px auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 24px 0px rgba(91, 91, 91, 0.23);
}
.contact-title{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ae141d;
}
.contact-txt{
  display: block;
  font-size: 16px;
  line-height: 40px;
}
.contact-code{
  img{
    width: 253px;
    height: 346px;
  }
}
.button-content {
  .ant-form-item-control-input-content {
    text-align: center;
    button {
      width: 200px;
      height: 64px;
      background-color: #af141c;
      border-radius: 8px;
      margin-top: 40px;
      color: #fff;
      font-size: 18px;
      margin-top: 0;
    }
  }
}
.tips {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #af141c;
}
// tab样式
.ant-tabs-nav-wrap{
  border-bottom: 6px solid #af141c;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
  color: #ffffff;
  background: #af141c;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
  border: none;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #ffffff;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab{
  border-radius: 0;
}

