.foundation-project {
  .welfare-title {
    margin-bottom: -4px;
    height: 48px;
    span {
      display: block;
      width: 210px;
      height: 48px;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      padding-left: 10px;
      line-height: 48px;
    }
  }
  .welfare-content {
    height: 296px;
    background-color: #af141c;
    margin-bottom: 50px;
    padding: 24px 22px;
    width: 100%;
    .swiper-item {
      background: #fff;
      width: 372px;
      height: 227px;
      display: block;
      transition: all 0.5s;
      &:hover {
        transform: translateY(-10px);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .swiper-pagination {
      width: 1156px;
      display: flex;
      justify-content: center;
    }
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      display: inline-block;
      background-color: rgb(255, 255, 255);
      border-radius: 50%;
      margin-top: 3px;
      margin-right: 10px;

    }
    .swiper-pagination-bullet-active {
      background: #e8b773;
    }
  }
  .welfare-dt {
    .welfare-dt-title {
      margin-bottom: -4px;
      height: 48px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border-bottom: 4px solid #af141c;
      margin-bottom: 20px;
      span {
        display: block;
        width: 210px;
        height: 48px;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        padding-left: 70px;
        line-height: 48px;
      }
    }
  }
  .welfart-dt-content {
    .ant-pagination {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      span {
        color: #333333;
      }
      span[disabled] {
        color: #999;
      }
    }
    .ant-pagination-item-active {
      border-color: #af141c;
      background-color: #af141c;
      a {
        color: #fff;
      }
    }
  }
  .swiper-container {
    padding: 10px 0;
    box-sizing: border-box;
  }
  // tab样式
  .ant-tabs-nav-wrap{
    border-bottom: 6px solid #af141c;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
    color: #ffffff;
    background: #af141c;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
    border: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #ffffff;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab{
    border-radius: 0;
  }
}

