.text-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 85px;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
  text-decoration: none;
  color: #333;
  font-weight: 700;
  &:hover {
    .text-item-title {
      color: #af141c;
      transition: color 0.5s;
    }
    .text-item-watch {
      svg {
        color: #af141c;
        transition: color 0.5s;
      }
    }
  }
  .text-item-time {
    color: #e8b773;
    font-size: 20px;
    line-height: 85px;
    width: 180px;
    text-align: center;
    font-weight: 700;
  }
  .text-item-title {
    flex: 1;
    line-height: 85px;
    color: #333333;
    font-size: 18px;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .text-item-watch {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 24px;
      color: #999;
    }
  }
}

