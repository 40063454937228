
.activity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  .ImgItem {
    width: 590px;
    height: 237px;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 22px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    // white-space: nowrap; /*超出的空白区域不换行*/
    // overflow: hidden; /*超出隐藏*/
    // text-overflow: ellipsis; /*文本超出显示省略号*/
    &:hover {
      box-shadow: 0px 5px 8px 0px rgba(175, 20, 28, 0.23);
      h4,
      span {
        color: #af141c;
      }
    }
    img {
      width: 259px;
      height: 195px;
      border-radius: 4px;
      background: #eee;
      margin-right: 20px;
    }
    .ImgItem-content {
      display: flex;
      max-width: 267px;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      color: #333;
      h4 {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        max-width: 267px;
        margin-top: 20px;
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      span {
        margin-bottom: 20px;
        align-self: end;
      }
    }
  }
  .ant-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    span {
      color: #333333;
    }
    span[disabled] {
      color: #999;
    }
  }
  .ant-pagination-item-active {
    border-color: #af141c;
    background-color: #af141c;
    a {
      color: #fff;
    }
  }
}


