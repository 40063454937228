.article-container {
  background-color: #fff;
  padding-bottom: 120px;
  .title {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    h4 {
      font-size: 30px;
      color: #333;
      margin-top: 30px;
    }
    div{
      display: flex;
      flex-direction: row;
      padding: 0 0 20px 0;
      color: #999;
    }
  }
  .content {
    padding-top: 20px;
    p{
      text-indent: 2em;
    }
    div span{
      display: inline-block;
      text-indent: 2em;
    }
    // img {
    //   width: 100%;
    //   height: auto;
    // }
  }
  .breadcrumb{
    padding-top: 20px;
  }
  .video_content{
    video{
      width: 100%;
    }
  }
}

