.foundation-info {
  // 基金会章程
  .foundation-constitution {
    background: #ffffff;
    box-shadow: 0px 11px 29px 0px rgba(91, 91, 91, 0.23);
    background-size: 100% !important;
    margin-bottom: 50px;
    overflow: hidden;
    h4 {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-top: 80px;
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 95px;
        height: 1px;
        background: #af141c;
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .constitution-img {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 50px;
      .read {
        width: 210px;
        height: 48px;
        color: #fff;
        font-size: 18px;
        line-height: 48px;
        text-align: center;
        margin-top: 40px;
      }
    }
  }
  .foundation-system {
    height: 356px;
    margin-bottom: 50px;
  }
  // 管理制度内容
  .foundation-system-content {
    box-sizing: border-box;
    padding: 50px 0;
    height: 318px;
    width: 100%;
    background-size: 100% !important;
    .foundation-system-list {
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      li {
        list-style: none;
        width: 590px;
        height: 85px;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        a {
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          font-weight: 700;
          flex: 1;
          text-decoration: none;
          color: #333;
          font-size: 18px;
          line-height: 85px;
          padding: 0 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          &:hover {
            color: #af141c;
          }
          img {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
  // 管理制度列表
  .foundation-system-title {
    margin-bottom: -4px;
    height: 48px;
    span {
      display: block;
      width: 210px;
      height: 48px;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      padding-left: 30px;
      line-height: 48px;
    }
  }
  .annual-report {
    margin: 0 auto 50px;
    padding: 0;
    li {
      list-style: none;
      width: 100%;
      height: 85px;
      background-color: #fff;
      border-radius: 4px;
      display: flex;
      margin-bottom: 20px;
      overflow: hidden;

      a {
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        font-weight: 700;
        flex: 1;
        text-decoration: none;
        color: #333;
        font-size: 18px;
        line-height: 85px;
        padding: 0 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &:hover {
          color: #af141c;
        }
        img {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

